<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6"
  >
    <circle cx="10.5" cy="10.5" r="9.5" stroke-width="2" />
    <line x1="18.4142" y1="18" x2="24" y2="23.5858" stroke-width="2" stroke-linecap="round" />
  </svg>
</template>
